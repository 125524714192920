<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                国投证券（原“安信证券”）&非凸科技，开展量化行业高质量交流活动
              </p>
              <div class="fst-italic mb-2">2023年4月26日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >沙龙活动</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/35/01-活动现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    4月26日，国投证券联合非凸科技开展的“为科技而生·赋能量化”沙龙活动，在上海国投大厦顺利举办。本次活动特别邀请到量化管理人鸣石基金、锋滔资产，以不同视角与行业伙伴共同探讨了算法赋能及量化发展趋势。
                  </p>
                  <p>
                    会上，主办方国投证券上海浦西分公司总经理须刚对本次活动发表致辞，国投证券在去年进行了组织架构上的战略调整，成立了私募机构部等客群部门，并通过种子基金和金融科技上大量资金投入等资源的加持，结合信息技术委员会、托管部、数字金融部等部门，为机构提供全方面多维度的服务支持，围绕机构业务开展工作，希望在证券市场机构化的浪潮中奋辑前行。此次，国投证券与非凸科技合作落地智能算法接入，完善交易服务体系，充分满足机构客户多样化、专业化的交易需求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/35/02-安信致辞.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技联合创始人&CEO王浚澎就智能算法赋能量化交易主题进行了分享，与现场行业专家展开了深度交流。非凸科技通过使用机器学习、深度学习等人工智能技术，以及强大数据分析能力，帮助机构客户显著提高超额收益，并基于Rust全栈开发和超强算力支持，保证算法交易策略持续高效迭代。非凸算法已成功上线国投证券，为机构客户提供智能算法能力与高性能交易体验的同时，也将持续为机构业务拓展和服务提供有力支持。
                  </p>
                  <p>
                    王浚澎表示，感谢国投证券对非凸科技的品牌重视与高度配合，我们可以顺利推进这次行业共聚共议的活动，从技术到观点都做到了开放友好。接下来，我们希望能够与更多券商合作，为金融与技术的共生融合搭建交流平台，分享前沿技术、最佳实践，探讨行业趋势、发展机遇，也期待我们的交流成果能为行业前行贡献一份力量。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/35/03-路演现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    鸣石基金分享嘉宾杨雪从量化投资发展历程及业务合作模式的角度做了分享。中国量化已度过黄金时代，超额Alpha收益会逐步衰退，相比主观策略，量化策略业绩稳定性更为突出，相对美国成熟的金融市场，中国量化仍存在较⼤优势。鸣石基金将持续对标全球一线量化投资机构，力争为客户创造持续稳健的投资回报。
                  </p>
                  <p>
                    锋滔资产分享嘉宾钱翼刚在会上介绍了公司的历史背景、技术优势以及产品特色。锋滔资产主要通过吸收国外先进的经验和技术并结合中国市场的特色，通过资本收益和风险暴露之间的平衡，打造多样化的量化策略模型，从而为投资者实现最优的资产配置。
                  </p>
                  <p>
                    最后，国投证券私募机构部项目经理吴义铭详细介绍了安信算法的使用、接入方式以及个性化TCA绩效分析服务，也希望与非凸科技的合作，可以发挥各自领域资源优势，实现共享互利、强强联合。国投证券打造了一整套满足不同客户诉求的机构交易服务体系，其中，安信算法交易服务，支持非凸算法等多种算法策略，满足客户专业交易需求，提供以最佳执行为目标的自动化证券交易执行服务。除此之外，安信TCA绩效分析服务，能为管理人的算法执行成本、执行质量以及市场冲击提供有效的度量和归因。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/35/04-茶歇.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技将继续以深度沙龙的形式与券商机构共同开展量化行业高质量交流活动，从数据挖掘到性能提升，从技术研发到策略迭代，从服务升级到生态合作，汇聚真知，碰撞灼见。
                  </p>
                  <p>
                    非凸科技也将持续通过科技创新发挥自身优势，围绕机构客户需求对产品和服务进行全面升级迭代，为金融机构提供更多创新解决方案，从而促进各生态伙伴合作共赢，赋能量化行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News35",
};
</script>

<style></style>
